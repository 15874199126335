import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

import Layout from "../components/Layout";

export const MaintenancePageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    <section className="home-hero">
      <InView threshold={0.2}>
        {({ ref, inView }) => (
          <motion.div
            ref={ref}
            initial={{ opacity: 0, transform: "translate(-50px)" }}
            animate={
              inView
                ? { opacity: 1, transform: "translate(0px)" }
                : { opacity: 1 }
            }
            transition={{
              duration: 0.5,
              delay: 0.25,
            }}
            className="ontopyo"
          >
            <h1
              data-scroll
              data-scroll-direction="horizontal"
              data-scroll-speed="2"
              data-scroll-position="top"
              data-scroll-delay="0.2"
            >
              Maintenance
              <br />& Engineering
            </h1>
          </motion.div>
        )}
      </InView>

      <div className="scroll-line">
        <div className="center" />

        <div data-scroll data-scroll-speed="-1" className="marker" />
      </div>

      <div className="video-container">
        <div className="video-overlay" />
        <img src="../img/hero-maint.jpg" />
      </div>
    </section>
    <div className="contact-heli">
      <div className="intro-heli">
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
            >
              <p data-scroll data-scroll-speed="-1" data-scroll-delay="0.2">
                Excel Helicopters is an EASA Part 145 Approved Helicopter
                Maintenance Organisation, world famous for its Gazelle
                Helicopter Total Maintenance Services.
              </p>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
            >
              <p data-scroll data-scroll-speed="-2" data-scroll-delay="0.2">
                The Company is structured and equipped to provide a first class
                Engineering Service for both Corporate & Private Clients,
                whatever their needs.
              </p>
            </motion.div>
          )}
        </InView>
      </div>
    </div>
    <div className="heli-grey-top">
      <div className="grey-intro">
        <p>We are EASA / CAA approved to provide maintenance services on various helicopter models, including:</p>
        <div className="grey-intro-grid">
        <div className="grey-item">
            <img src="../img/helicop.png"/>
            <p>Airbus/ Aerospatiale SA341 / SA342 Gazelle series</p>
          </div>
          <div className="grey-item">
            <img src="../img/helicop.png"/>
            <p>Airbus AS355 Twin Squirrel series</p>
          </div>
          <div className="grey-item">
            <img src="../img/helicop.png"/>
            <p>Airbus AS350 Single Squirrel series</p>
          </div>
          <div className="grey-item">
            <img src="../img/helicop.png"/>
            <p>Agusta Westland A109 series</p>
          </div>
          <div className="grey-item">
            <img src="../img/helicop.png"/>
            <p>Bell / Agusta 206 Jet Ranger & Long Ranger series</p>
          </div>
        </div>
      </div>
    </div>
    <div className="block-heli-pad">
      <div className="twoup">
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <h2>What else</h2>
              <span className="heli-line" />
              <p>
              In addition to scheduled and un-scheduled maintenance work on helicopters, we routinely perform aircraft re-furbishments and upgrades, including:
              </p>
              <span className="heli-line" />
              <div className="bullets-container">
                <div className="bullets">
                  <img src="../img/bullet.svg" />
                  <div classname="stack">
                    <p>Avionics modifications & upgrades</p>
                  </div>
                </div>
                <div className="bullets">
                  <img src="../img/bullet.svg" />
                  <div classname="stack">
                    <p>Exterior re-sprays</p>
                  </div>
                </div>
                <div className="bullets">
                  <img src="../img/bullet.svg" />
                  <div classname="stack">
                    <p>Interior re-trims and upgrades</p>
                  </div>
                </div>

              </div>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <p>
              Our dedicated, experienced and highly motivated team of aircraft engineers, lead by Hossein Seylani, will ensure that the finished product meets all the specific requirements.
              <br/><br/>
Our technical records department uses state of the art computer technology and software to track and record historical data and maintenance needs of all customer helicopters.
<br/><br/>
We are also able to cater for American registered helicopter maintenance. Our aim is to get all maintenance mork done to the highest standards, efficiently, quickly, cost-effectively, but with no compromise to the aircraft safety.
              </p>
            </motion.div>
          )}
        </InView>
      </div>
    </div>
    <div className="heli-grey shorten">
      <div className="twoup">
        <div className="item">
          <div className="item-image-holder">
            <img
              data-scroll
              data-scroll-speed="1.5"
              data-scroll-delay="0.2"
              className="ig50"
              src="../img/maint-img.jpg"
            />
          </div>
        </div>
        <div className="item special">
          <p data-scroll data-scroll-speed="3.5" data-scroll-delay="0.2">
            all maintenance work is done to the highest standards, efficiently &
            cost-effectively with no compromise to the aircraft’s safety.
          </p>
        </div>
      </div>
    </div>
  </div>
);

MaintenancePageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const MaintenancePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <MaintenancePageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

MaintenancePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default MaintenancePage;

export const pageQuery = graphql`
  query MaintenancePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            text
          }
          heading
          description
        }
      }
    }
  }
`;
